import { DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import useStyles from '../styles';
import { Form } from 'react-final-form';
import NewEventTypeForm from './NewEventTypeForm';
import { createEventType } from '../../api';

const NewEventTypeDialogContent = ({calendar_id, closeNewEventTypeInput, filteredServices}) => {
    const classes = useStyles();
    return (
        <>
            <DialogTitle className={classes.dialogTile}>
                <div className={classes.mainHeading}>
                    Event setup
                </div>
                <div className={classes.generalText}>
                    Create an event
                </div>
            </DialogTitle >
            <DialogContent sx={{ overflowY: 'scroll' }}>
                <Form
                    onSubmit={(values) => {
                        createEventType({calendar_id,...values}).then(
                            (r)=>{
                                closeNewEventTypeInput();
                                window.location.reload();
                            }
                        )
                    }}
                    closeNewEventType={closeNewEventTypeInput}
                    filteredServices={filteredServices}
                    component={NewEventTypeForm}
                />
            </DialogContent>
    </>
    )
}

export default NewEventTypeDialogContent;
