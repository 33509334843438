import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import useStyles from '../styles';
import { Button, DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { Form } from 'react-final-form';
import MultiSelector from '../../components/FormComponents/MultiSelector';
import { FieldDateTimePicker } from '../../components/FormComponents/FieldDateTimePicker';
import { useStores } from '@a2d24/care-at-work-state/stores';
import { useBookings } from '../../api';
import { useSnackbar } from 'notistack';

const EditUnavailabilityForm = ({
    handleSubmit,
    dirty,
    form,
    submitting,
    values,
    errors,
    onCancel,
    fields,
    index,
    eventTypes
}) => {
    const classes = useStyles();
    const { mutators } = form;
    const [minEndRange, setMinEndRange] = useState(fields.value[index] !== undefined? Date.parse(fields.value[index].start_datetime):undefined);
    const [invalidDate, setInvalidDate] = useState(true)
    useEffect(() => {
        if(values.start_datetime===undefined || values.end_datetime===undefined){
            setInvalidDate(true);
        }
        else{
            if(values.start_datetime > values.end_datetime){
                setInvalidDate(true);
            }
            else{
                setInvalidDate(false);
            }
        }
    }, [values]);
    return (
        <form style={{width:'100%'}} onSubmit={handleSubmit}>
          <div className={classes.UnavailabilityFormContainer} style={{marginTop: '15px'}}>
              <FieldDateTimePicker
                name={'start_datetime'}
                label={'Start'}
                onChange={setMinEndRange}
                minuteStep={5}
                validationFn={(val)=>{if(!val){return "Required"}}}
              />
            <FieldDateTimePicker
                name={'end_datetime'}
                label={'End'}
                minuteStep={5}
                minDateTime={minEndRange}
                disabled={minEndRange === undefined}
                validationFn={(val)=>{
                    if(!val){return "Required"}
                }}
              />
            <MultiSelector
                id={`event_types`}
                description={"Event types"}
                options={eventTypes}
                optional={false}
            />
            <div className={classes.ActionDiv}>
              {/*{dirty && <div>{errors.general}</div>}*/}

              <div className={classes.advancedSettings}>
                <Button
                  onClick={onCancel}
                  style={{ margin: '5px' }}
                  variant="contained"
                  color={'primary'}
                >
                  Cancel

                </Button>
              </div>
              <Button
                type={'submit'}
                disabled={submitting||invalidDate}
                style={{ margin: '5px' }}
                variant="contained"
                color={'primary'}
              >
                Apply
              </Button>
            </div>
          </div>
        </form>
    );
};



const EditDialog = ({ open, handleDialogClose, fields, index, eventTypes, initialValues}) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const { authStore } = useStores();
    const bookings = useBookings(authStore.calendarId);
    const checkBookingConflicts = ({values}) => {
        for(const booking of bookings.data??[]){
            if(values.event_types.includes(booking.type)){
                const bookingStart = new Date(booking.start);
                const bookingEnd = new Date(booking.end);
                if(values.start_datetime >= bookingStart && values.start_datetime < bookingEnd){
                    return true;
                }
                if(values.end_datetime > bookingStart && values.end_datetime <= bookingEnd){
                    return true;
                }
            }
        }
        return false;
    }


  const insertUnavailability = (values)=>{
        if(checkBookingConflicts({ values })){
            enqueueSnackbar(`Can not add blocked slots as it conflicts with scheduled booking`, { variant: 'error' });
        }
        else {
            fields.update(index, values);
            handleDialogClose();
        }
  }
  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
    >
      <>
        <DialogTitle className={classes.dialogTile}>
          <div className={classes.mainHeading}>
            Select the date and time you are unavailable
          </div>
        </DialogTitle>
        <DialogContent sx={{ overflowY: 'scroll' }}>
          <Form
                    onSubmit={(values) => {
                      insertUnavailability(values);
                    }}
                    fields={fields}
                    index={index}
                    onCancel={handleDialogClose}
                    eventTypes={eventTypes}
                    component={EditUnavailabilityForm}
                    initialValues={initialValues}
                />
            </DialogContent>
        </>

        </Dialog>
    );
};


export default EditDialog;
