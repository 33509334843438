import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment/moment';
import { reFormat } from '@a2d24/care-at-work-template-module/widgets/utils';

const API_URL = process.env.REACT_APP_APPOINTLOGIC_ENDPOINT;
const APP_API_URL = process.env.REACT_APP_API_ENDPOINT;

const transform_calendar = ({ calendarConfig, clientsServiced })=>{
    const {calendar, blocked_slots, event_types} = calendarConfig;
    const workingHours = {
        '1_working_hours': [],
        '2_working_hours': [],
        '3_working_hours': [],
        '4_working_hours': [],
        '5_working_hours': [],
        '6_working_hours': [],
        '7_working_hours': [],
    }
    const blockedSlots = {};
    const eventTypeNameMap = {};
    event_types.forEach((eventType)=>{
        eventTypeNameMap[eventType["event_type_id"]] = eventType["name"];
    });
    blocked_slots.forEach(
        (blocked_slot)=>{
            if(blockedSlots[`${blocked_slot.slot.start_datetime}#${blocked_slot.slot.end_datetime}`]){
                blockedSlots[`${blocked_slot.slot.start_datetime}#${blocked_slot.slot.end_datetime}`].push(blocked_slot.event_type_id)
            }
            else{
               blockedSlots[`${blocked_slot.slot.start_datetime}#${blocked_slot.slot.end_datetime}`] = [blocked_slot.event_type_id]
            }

        }
    )
    const unavailability = Object.keys(blockedSlots).map(
        (slotKey)=>{
            const slots = slotKey.split("#");
            return{
                start_datetime:slots[0],
                end_datetime:slots[1],
                event_types: blockedSlots[slotKey]
            }
        }

    )

    for(const slot of calendar.availability){
        const workingHourSlot = {
            'start_datetime': moment(slot.start_time, ['h:m a', 'H:m']).toISOString(),
            'end_datetime': moment(slot.end_time, ['h:m a', 'H:m']).toISOString(),
            'event_types': []
        }
        for(const event_type of event_types) {
            for (const available_slot of event_type.availability??[]) {
                if (slot.day === available_slot.day && slot.start_time === available_slot.start_time && slot.end_time === available_slot.end_time) {
                    workingHourSlot.event_types.push(event_type.event_type_id);
                }
            }
        }

        workingHours[`${slot.day}_working_hours`].push(workingHourSlot)
    }
    const other_settings = {
        contact_number: calendar?.meta_settings?.contact_number,
        clients_serviced: clientsServiced,

    }

    return {
        ...workingHours,
        ...calendar,
        ...other_settings,
        event_types,
        unavailability
    }
}

const fetchClientsServiced = () =>{
    return axios.get(`${APP_API_URL}/AppointlogicController/get_clients_serviced_for_dsp`).then((r)=>r.data)
}

export const saveClientsServiced = ({clients_serviced})=>{
    return axios.post(`${APP_API_URL}/AppointlogicController/update_clients_serviced`, {clients_serviced}).then((r)=>r.data)
}
export const fetchCalendar = ({ calendar_id }) => {
    return Promise.all(
        [
            axios.get(
                `${API_URL}/tenant_portal/calendar/${calendar_id}`,
            )
            .then(r=>r.data),
            fetchClientsServiced()
        ]
    ).then(
        (values)=>{
            return transform_calendar(
                {
                    calendarConfig: values[0],
                    clientsServiced: values[1]
                }
            )

        }
    )
};

export const useCalendar = (calendar_id) => {
    const queryIndex = ['calendar'];
    return useQuery(queryIndex, () => fetchCalendar({ calendar_id }));
};

export const fetchServices = () => {
    return axios.get(`${APP_API_URL}/ConsultController/get_consult_types`).then((res) => {
        return res.data.map((consultType) => {
            return {
                value: consultType,
                label: reFormat(consultType),
            };
        });
    });
};

export const useServices = () => {
    const queryIndex = ['consultTypes'];
    return useQuery(queryIndex, () => fetchServices(), { retry: 5, retryDelay: 3000 });
};


export const createEventType = ({calendar_id, name, duration, limits, services}) => {
    // Duration field is in minutes
    const duration_seconds = duration * 60;
    const transformedLimits = {
        minimum_notice_seconds: limits.minimum_notice * 3600,
        cancellation_period_seconds: limits.cancellation_period * 3600,
        event_per_slot:limits.event_per_slot
    }
    const meta_settings = {
        services
    }
    return axios.post(
        `${API_URL}/tenant_portal/create_event_type`,
        {calendar_id, name, duration_seconds, limits:transformedLimits, meta_settings, is_bookable_by_end_user: true}).then(res =>res)
}


export const updateCalendar = ({calendar_id, calendar_version, availability, meta_settings, block_public_holidays}) => {
    return axios.post(
        `${API_URL}/tenant_portal/save_calendar_settings`,
        {calendar_id, calendar_version, availability, meta_settings, block_public_holidays}).then(res =>res)
}

export const updateBlockedSlots = ({calendar_id, event_type_id, blocked_slots}) => {
    return axios.post(
        `${API_URL}/tenant_portal/save_blocked_slots`,
        {
            calendar_id,
            event_type_id,
            blocked_slots
        }
    ).then(res =>res)
}

export const updateEventType = ({calendar_id, event_type_id, event_type_version, availability}) => {
    return axios.post(
        `${API_URL}/tenant_portal/update_event_type`,
        {
            calendar_id,
            event_type_id,
            event_type_version,
            availability
        }).then(res =>res)
}

export const fetchBookings = ({calendar_id}) => {
    return axios.get(`${API_URL}/tenant_portal/booking_slots/${calendar_id}`).then((r)=>r.data)
}

export const useBookings = (calendar_id) => {
    const queryIndex = ['bookings'];
    return useQuery(queryIndex, () => fetchBookings({calendar_id}), { retry: 5, retryDelay: 3000 });
};


export const deleteEventType = ({calendar_id, event_type_id}) => {
    return axios.delete(`${API_URL}/tenant_portal/delete_event_type/${calendar_id}/${event_type_id}`).then((r)=>r.data)
}
